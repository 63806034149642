<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<!-- 搜索栏 -->
				<a-row>
					<a-form-item label="一卡通名称" name="title" class="ui-form__item">
						<a-input v-model:value="formState.title" placeholder="请输入一卡通名称"></a-input>
					</a-form-item>

					<!-- <a-form-item label="一卡通卡号" name="cardNo" class="ui-form__item">
						<a-input v-model:value="formState.cardNo" placeholder="请输入一卡通卡号"></a-input>
					</a-form-item> -->

					<a-form-item label="用户信息" name="userInfo" class="ui-form__item">
						<a-input v-model:value="formState.userInfo" placeholder="请输入购买用户信息"></a-input>
					</a-form-item>

					<a-form-item label="交易流水号" name="outTradeNo" class="ui-form__item">
						<a-input v-model:value="formState.outTradeNo" placeholder="请输入交易流水号"></a-input>
					</a-form-item>

					<a-form-item label="订单号" name="orderNo" class="ui-form__item">
						<a-input v-model:value="formState.orderNo" placeholder="请输入订单号"></a-input>
					</a-form-item>

					<a-form-item label="卡状态" name="status" class="ui-form__item">
						<a-select v-model:value="formState.status" placeholder="请选择一卡通状态" allow-clear
							style="width: 195px;">
							<a-select-option :value="0">待激活</a-select-option>
							<a-select-option :value="1">正常</a-select-option>
							<a-select-option :value="2">转赠中</a-select-option>
							<a-select-option :value="3">已转赠</a-select-option>
							<a-select-option :value="5">售后待审核</a-select-option>
							<a-select-option :value="6">已售后</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item label="卡类型" name="cardType" class="ui-form__item">
						<a-select v-model:value="formState.cardType" placeholder="请选择卡类型" allow-clear
							style="width: 195px;">
							<a-select-option :value="1">普通卡</a-select-option>
							<a-select-option :value="2">至尊卡</a-select-option>
							<a-select-option :value="3">礼品卡</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="交易时间" name="payTime" class="ui-form__item">
						<a-range-picker v-model:value="formState.payTime"></a-range-picker>
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="18">
						<span v-permission="['reportForms_finance_card_export']">
							<exportReport type="userUnionCardOrderStrategy" :searchData="searchData"></exportReport>
						</span>
					</a-col>
					<a-col :span="6" style="text-align: right">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 20px;">
				<a-table :pagination="pagination" :columns="columns" :dataSource="list" rowKey="id"
					:scroll="{ x: 2500 }">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'cardType'">
							<div v-if="record.cardType === 1">普通卡</div>
							<div v-if="record.cardType === 2">主题卡</div>
							<div v-if="record.cardType === 3">礼品卡</div>
						</template>
						<template v-if="column.key === 'cardStatus'">
							{{ ['待激活', '正常', '转赠中', '已转赠', '', '售后待审核', '已售后'][record.cardStatus] }}
						</template>
						<template v-if="column.key === 'status'">
							{{ ['待支付', '已完成', '售后待审核', '售后完成'][record.status] }}
						</template>
						<template v-if="column.key === 'userInfo'">
							<div>
								<div>用户昵称：{{ record.nickname || '-' }}</div>
								<div>用户手机号：{{ record.phone || '-' }}</div>
							</div>
						</template>
						<template v-if="column.key === 'parPrice'">
							{{ record.unionCard.parPrice }}
						</template>
						<template v-if="column.key === 'payTime'">
							{{ transDateTime(record.payTime) }}
						</template>
						<template v-if="column.key === 'fees'">
							{{ (record.payment * 0.0054).toFixed(3) }}
						</template>
						<template v-if="column.key === 'price'">
							{{ (record.payment - (record.payment * 0.0054)).toFixed(3) }}
						</template>
						<template v-if="column.key === 'afterInfo'">
							<div v-if="record.status === 3">
								<div>退款金额：{{ record.orderAfterSaleRefund || '-' }}</div>
								<div>售后时间：{{ transDateTime(record.orderAfterSaleCreateTime) }}</div>
								<div>操作人：{{ record.orderAfterSaleHandleUserName || '-' }}</div>
							</div>
							<div v-else>-</div>
						</template>
						<template v-if="column.key === 'action'">
							<a-button v-if="record.status === 1 && !record.refundStatus && record.refundStatus !== 0" type="link" @click="onRefund(record)">售后</a-button>
							<div v-else-if="record.status === 2">
								<a-button type="link" size="small" @click="onConfirm(record)">通过</a-button>
								<a-button type="link" danger size="small" @click="onReject(record)">拒绝</a-button>
							</div>
							<div v-else>-</div>
						</template>
					</template>
				</a-table>
			</div>
		</a-spin>
		
		<a-modal v-model:visible="visible" title="售后" width="650px" @ok="onRefundOk">
			<a-spin :spinning="loading">
				<a-form ref="refundForm" :model="modelRef" :labelCol="{span: 5}" :wrapperCol="{ span: 18 }">
					<a-form-item label="实付金额">
						{{ modelRef.payment1 }}
					</a-form-item>
					
					<a-form-item label="退款金额" name="payment" :rules="[{required: true, message: '必填项不允许为空'}]">
						<a-input-number v-model:value="modelRef.payment" :min="0" :precision="0" placeholder="请输入"></a-input-number>
					</a-form-item>
				</a-form>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	import exportReport from '@/components/exportReport/exportReport.vue';
	import {
		getUnionCardOrderList
	} from "@/service/modules/report";
	import { unionCardOrderRefund, unionCardOrderConfirmAfterSale, unionCardOrderRejectAfterSale } from '@/service/modules/coupon.js';

	export default {
		components: {
			exportReport
		},
		data() {
			return {
				showModal: false,
				isEdit: false,
				isSee: false,
				id: 0,
				loading: false,
				searchData: {},
				modelRef: {},
				formState: {},
				columns: [{
					title: '用户信息', //（用户昵称、所属用户id、用户手机号）
					key: 'userInfo',
				}, {
					title: '一卡通名称',
					dataIndex: 'title',
				}, {
					title: '卡类型',
					key: 'cardType',
					width: 120
				}, {
					title: '卡状态',
					key: 'cardStatus',
					width: 120
				}, {
					title: '订单号',
					dataIndex: 'orderNo',
					width: 190
				}, {
					title: '订单状态',
					key: 'status',
					width: 120
				}, {
					title: '交易流水号',
					dataIndex: 'outTradeNo',
					width: 250
				}, {
					title: '初始金额',
					key: 'parPrice',
					width: 130
				}, {
					title: '实付金额',
					dataIndex: 'payment',
					width: 100
				}, {
					title: '微信手续费（0.54%）',
					// key: 'fees',
					dataIndex: 'wechatFee'
				}, {
					title: '微信到账预估金额',
					// key: 'predict',
					dataIndex: 'receivePayment'
				}, {
					title: '交易时间',
					key: 'payTime',
					width: 190
				}, {
					title: '售后信息',
					key: 'afterInfo',
				}, {
					title: '操作',
					key: 'action',
					width: 130,
					fixed: 'right'
				}],
				list: [],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					pageSizeOptions: ['10', '20', '50', '100', '500'],
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				visible: false,
				modelRef: {}
			}
		},
		created() {
			this.onSearch();
		},
		methods: {
			reset() {
				this.$refs.form.resetFields();
				this.onSearch();
			},
			onSearch() {
				this.pagination.current = 1;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.getData();
			},
			async getData() {
				if (this.formState.payTime && this.formState.payTime.length === 2) {
					this.searchData.payStartTime = this.moment(this.formState.payTime[0].startOf('day')).unix();
					this.searchData.payEndTime = this.moment(this.formState.payTime[1].endOf('day')).unix();
				}
				this.loading = true;
				try {
					let ret = await getUnionCardOrderList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData
					})
					this.loading = false;
					if (ret.code === 200) {
						this.list = ret.data.list;
						this.pagination.total = ret.data.totalCount;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			onRefund(item) {
				this.modelRef = {
					orderId: item.id,
					payment1: item.payment
				};
				this.visible = true;
			},
			onRefundCancel(){
				this.$refs.refundForm.resetFields();
				this.visible = false;
			},
			onRefundOk() {
				this.$refs.refundForm.validate().then(()=> {
					this.$confirm({
						title: '提示',
						content: '确定售后吗？',
						onOk: async () => {
							this.loading = true;
							let postData = JSON.parse(JSON.stringify(this.modelRef));
							delete postData.payment1;
							let ret = await unionCardOrderRefund(postData);
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('售后成功');
								this.getData();
								this.visible = false;
							}
						}
					})
				})
			},
			onConfirm(item) {
				this.$confirm({
					title: '提示',
					content: '确定同意售后吗？',
					onOk: async () => {
						this.loadig = false;
						let ret = await unionCardOrderConfirmAfterSale({
							orderAfterSaleId: item.orderAfterSaleId
						})
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('操作成功')
							this.getData();
						}
					}
				})
			},
			onReject(item) {
				this.$confirm({
					title: '提示',
					content: '确定拒绝售后吗？',
					onOk: async () => {
						this.loadig = false;
						let ret = await unionCardOrderRejectAfterSale({
							orderAfterSaleId: item.orderAfterSaleId
						})
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('操作成功')
							this.getData();
						}
					}
				})
			}
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>